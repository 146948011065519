import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) =>({
  container: {
    paddingTop: '2rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  card: {
    display: 'flex',
    position: 'relative',
    backgroundColor: Colors.cardBackgroundColor,
    borderRadius: 5,
    padding: '1rem',
    boxSizing: 'border-box',
    height: '11.25rem',
    width: '17rem',
    marginBottom: '1rem',
    marginRight: '1rem',
    textDecoration: 'none',
    color: Colors.heading,
    overflow: 'hidden',
    cursor: 'pointer',
    pointer: 'cursor',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    height: '4.5rem',
    marginBottom: '.5rem'
  },
  cardTitle: {
    fontSize: '1.375rem',
    fontWeight: 400,
    margin: 0,
    padding: 0
  },
  count: {
    position: 'absolute',
    fontWeight: 600,
    top: '-2rem',
    right: '-2.6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: Colors.primaryButtonColor,
    fontSize: '1rem',
    color: Colors.white,
    borderRadius: '4rem',
    padding: '.25rem',
    minWidth: '2.25rem',
    minHeight: '2.25rem'
  },
  innerContainer: {
    textAlign: 'center',
    position: 'relative',
    flex: 1,
    margin: '0 3rem'
  }
}))

function ItemTypeGrid (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const getCount = (type) => props.items.filter(item => item.type === type).length

  const renderItems = () => {
    if (props.types) {
      return props.types.map((type) => (
        <div
          key={type.type}
          onClick={() => props.setType(type.type)}
          className={classes.card}
        >


          <div className={classes.innerContainer}>
            <img src={type.icon} className={classes.icon} alt={type.type} />
            <h3 className={classes.cardTitle}>{t(type.plural)}</h3>
            {/*<div className={classes.count}>{getCount(type.type)}</div>*/}
          </div>
        </div>
      ))
    }
    return null
  }

  return (
    <div className={classes.container}>
       {renderItems()}
    </div>
  )
}

export default ItemTypeGrid
