import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'
import get from 'lodash/get'
import { DonutChart, Title } from '../../Components'
import { formatDateTime } from '../../Utils/dateTime'
import { Colors } from '../../Utils/theme'
import PollRankChart from './PollRankChart'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 800
  },
  card: {
    marginBottom: '2rem',
  },
  newLine: {
    marginBottom: '1rem'
  },
  chartContainer: {
    height: 400
  },
  timestamp: {
    color: Colors.heading,
    fontWeight: 700,
    marginBottom: '1rem'
  },
  textAnswerContainer: {
    backgroundColor: Colors.cardBackgroundColor,
    padding: '2rem',
    borderRadius: 5,
    marginTop: '1rem',
    marginBottom: '1.5rem'
  },
  noAnswers: {
    color: Colors.red,
    fontSize: '1.25rem',
    marginTop: '1rem',
  },
  questionNumber: {
    opacity: 0.7,
    fontSize: '1rem',
    borderTop: '1px solid rgba(0,0,0,.2)',
    paddingTop: '2rem',
    paddingBottom: '1rem'
  },
  questionDescription: {
    color: Colors.content,
    fontSize: '1rem',
    marginBottom: '2.5rem',
    padding: '0 0 1rem'
  }
}))

function PollResult(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const renderWithNewLines = (text) => {
    const componentDecorator = (href, text, key) => <a key={key} href={href} target='_blank' rel='noreferrer noopener'>{text}</a>
    return !text ? text : text.split('\n').map((line, index) => (
      <div key={index} className={classes.newLine}>
        <Linkify componentDecorator={componentDecorator}>{line}</Linkify>
      </div>
    ))
  }

  const renderNoAnswers = () => <div className={classes.noAnswers}>{t('no_answers_yet')}</div>

  const renderAnswers = (questionIndex) => {
    const currentQuestion = props.poll.questions[questionIndex]

    // Bad question format
    if (!currentQuestion || !currentQuestion.type) return null

    if (currentQuestion.type === 'text') {
      const result = props.pollAnswers.find(pa => pa.questionId === currentQuestion.id)

      if (!result || !result.textAnswers || !result.textAnswers.length) return renderNoAnswers()

      // Reverse answers to display latest first
      const reversedAnswers = [...result.textAnswers].reverse()

      return reversedAnswers.map(({ textAnswer, createdAt }, index) => {

        return (
          <div key={`${questionIndex}-${index}`} className={classes.textAnswerContainer}>
            <div className={classes.timestamp}>
              {formatDateTime(createdAt)}
            </div>
            {renderWithNewLines(textAnswer)}
          </div>
        )
      })
    } else if (currentQuestion.type === 'ranked_multiselect') {
      const series = currentQuestion.answersFi.map((_, answerIndex) => {

        const curAns = props.pollAnswers.find(pa => pa.questionId === currentQuestion.id)
        if (!curAns || !curAns.rankChartData) return 0

        const ansData = curAns.rankChartData.find(cd => cd.answerIndex === answerIndex)
        const ratioValue = ansData ? (ansData.ratio * 100 || 0) : 0

        return {
          ratio: ratioValue,
          rankRatios: ansData ? ansData.rankRatios || [] : []
        }

      })

      const charIndex = (number) => number < 26 ? String.fromCharCode(number + 65) : number

      const shorten = (str) => str.length > 37 ? str.slice(0, 32) + '...' : str

      const options = {
        labels: props.poll.questions[questionIndex].answersFi.map((ans, index) => shorten(`${charIndex(index)}. ${ans}`))
      }

      // Check if no answers
      if (!series.find(val => !!val)) return renderNoAnswers()

      return (
        <PollRankChart
          series={series}
          options={options}
        />
      )
    } else {
      const series = currentQuestion.answersFi.map((_, answerIndex) => {

        const curAns = props.pollAnswers.find(pa => pa.questionId === currentQuestion.id)
        if (!curAns || !curAns.chartData) return 0

        const ansData = curAns.chartData.find(cd => cd.answerIndex === answerIndex)
        return ansData ? (parseInt(ansData.count, 10) || 0) : 0
      })

      const charIndex = (number) => number < 26 ? String.fromCharCode(number + 65) : number

      const shorten = (str) => str.length > 37 ? str.slice(0, 32) + '...' : str

      const options = {
        labels: props.poll.questions[questionIndex].answersFi.map((ans, index) => shorten(`${charIndex(index)}. ${ans}`))
      }

      // Check if no answers
      if (!series.find(val => !!val)) return renderNoAnswers()

      return (
        <div className={classes.chartContainer}>
          <DonutChart
            series={series}
            options={options}
          />
        </div>
      )
    }
  }

  const renderQuestionDescription = (questionIndex) => {
    const desc = get(props.poll, `questions[${questionIndex}].contentFi`)
    if (!desc) return null

    return (
      <div className={classes.questionDescription}>
        {renderWithNewLines(desc)}
      </div>
    )
  }

  const getAnswerCount = () => get(props, 'poll.totalAnswers', 0)

  const renderQuestions = () => {
    const questions = get(props.poll, 'questions', [])
    return questions.map((q, index) => {
      return (
        <div>
          <div className={classes.questionNumber}>{`${t('question')} ${index + 1}.`}</div>
          <Title title={q.titleFi} type='subtitle' />
          {renderQuestionDescription(index)}
          {renderAnswers(index)}
        </div>
      )
    })
  }

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <Title title={props.poll.titleFi} />
        {renderWithNewLines(props.poll.contentFi)}
      </div>
      <Title title={`${t('answers')} (${getAnswerCount()})`} type='subtitle' />
      {renderQuestions()}
    </div>
  )
}

export default PollResult
