import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, DynamicItemForm } from '../../../Components'

const Item = (props) => {
  const { itemStore } = useStore()
  const { itemId }: any = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    itemStore.getItem(itemId)
  }, [])


  const updateItem = (item) => {
    itemStore.updateItem(itemId, item)
  }

  const toggleActive = () => {
    itemStore.updateItem(itemId, {
      active: !itemStore.item.active
    })
  }

  return (
    <>
      <PageHeader
        title={itemStore.item ? t(itemStore.item.type) : t('item')}
      />
      {itemStore.item && itemStore.item.id === Number(itemId) && (
        <DynamicItemForm
          item={itemStore.item}
          onSave={updateItem}
          toggleActive={toggleActive}
          type={get(itemStore.item, 'type')}
          mode='edit'
        />
      )}
    </>
  )
}

export default observer(Item)
