import { createMuiTheme } from '@material-ui/core/styles'
import Color from 'color'

export const Fonts = {
  heading: 'Rubik',
  content: 'Roboto'
}

// Brand color palette
const BRAND = {
  heading: '#000000',
  content: '#333333',
  brandColor1: '#008b62', // red
  brandColor2: '#3A3B3C' // '#646464' // blue
}

// Brand colors
const THEME = {
  brandColor1: BRAND.brandColor1,
  brandColor2: BRAND.brandColor2,

  heading: BRAND.heading,
  content: BRAND.content,

  primaryButton: BRAND.brandColor1,
  secondaryButton: '#000000',
  sidebarBackground: BRAND.brandColor2,
  loginSidebarBackgroundColor: BRAND.brandColor1,
  tableHeaderBackground: Color(BRAND.brandColor2).darken(0.3).saturate(0.5).hex(),
  cardBackground: Color(BRAND.brandColor2).alpha(0.05).string(),
}


export const Colors = {
  // Named colors
  black: '#000000',
  black70: 'rgba(0, 0, 0, .7)',
  white: '#ffffff',
  green: '#3EB973', // use only for success, valid etc.
  yellow: '#fcdb03',
  red: '#FF1744', // use only for failure / alert / delete etc.
  lightGrey: '#f4f4f4',

  // Buttons
  primaryButtonColor: THEME.primaryButton,
  primaryButtonHoverColor: Color(THEME.primaryButton).alpha(0.9).string(),
  secondaryButtonColor: THEME.secondaryButton,
  secondaryButtonHoverColor: Color(THEME.secondaryButton).alpha(0.9).string(),

  // Texts
  heading: THEME.heading,
  content: THEME.content,
  label: '#4c4c4c',

  // Backgrounds
  sidebarBackgroundColor: THEME.sidebarBackground,
  loginSidebarBackgroundColor: THEME.loginSidebarBackgroundColor,
  pageHeaderBackgroundColor: THEME.cardBackground,
  cardBackgroundColor: THEME.cardBackground,

  deletedOverlayColor: 'rgba(225, 225, 225, .5)',

  // Tables
  tableHeaderBackgroundColor: THEME.tableHeaderBackground,
  tableHeaderTextColor: '#ffffff',
  tableContentColor: THEME.heading,
  tableRowBackgroundColor: '#f4f4f4',

  // Border
  lightBorder: 'rgba(0,0,0,.2)',
}

// A custom theme for this app
const Theme = createMuiTheme({
  palette: {
    primary: {
      main: THEME.heading
    },
    secondary: {
      main: THEME.brandColor1
    },
    error: {
      main: Colors.red
    },
    background: {
      default: Colors.white
    }
  }
})

export default Theme
