import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Title, Input, Switch } from '../'
import { v4 as uuidv4 } from 'uuid'
import { Colors } from '../../Utils/theme'
import PollQuestionForm from './PollQuestionForm'
import PollLangButton from './PollLangButton'

const useStyles = makeStyles((theme) =>({
  container: {
    maxWidth: 700
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 400,
    margin: 0,
    padding: '0 0 .75rem',
    flex: 1
  },
  introContainer: {
    backgroundColor: Colors.lightGrey,
    padding: '1rem',
    border: '1px solid rgba(0,0,0,.2)',
    position: 'relative',
    marginBottom: '2rem'
  },
  introHeader: {
    flexDirection: 'row',
    paddingBottom: '1rem'
  },
  actionButtons: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
}))

function PollForm (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [langTab, setLangTab] = useState('fi')

  const [locked, setLocked] = useState(false)
  const toggleLock = () => setLocked(!locked)

  const addQuestion = () => {
    props.setQuestions([...props.questions, {
      id: uuidv4(),
      type: 'select',
      titleFi: '',
      titleEn: '',
      contentFi: '',
      contentEn: '',
      answersFi: ['', ''],
      answersEn: ['', '']
    }])
  }

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const toggleQuestionOptional = (questionIndex) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        return {
          ...question,
          isOptional: !question.isOptional
        }
      }
      return question
    }))
  }

  const setQuestionType = (questionIndex, type) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        question.type = type

        if (type !== 'text') {
          question.isOptional = false
          if (type === 'ranked_multiselect') {
            // Add empty answers fields if not set
            if (!question.answersFi || question.answersFi.length < 3) {
              question.answersFi = ['', '', '']
            }
            if (!question.answersEn || question.answersEn.length < 3) {
              question.answersEn = ['', '', '']
            }
          } else {
            // Type: select, multiselect

            // Add empty answers fields if not set
            if (!question.answersFi || !question.answersFi.length) {
              question.answersFi = ['', '']
            }
            if (!question.answersEn || !question.answersEn.length) {
              question.answersEn = ['', '']
            }
          }
        } else {
          // Set answers to empty arrays for type = text
          question.isOptional = true // Text answers are optional
          question.answersFi = []
          question.answersEn = []
        }
      }
      return question
    }))
  }

  const setQuestionTitle = (questionIndex, lang, value) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        question[(lang === 'en' ? 'titleEn' : 'titleFi')] = value
      }
      return question
    }))
  }

  const setQuestionContent = (questionIndex, lang, value) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        question[(lang === 'en' ? 'contentEn' : 'contentFi')] = value
      }
      return question
    }))
  }

  const deleteQuestion = (questionIndex) => {
    const confirm = window.confirm(t('confirm_delete_question'))
    if (confirm) {
      props.setQuestions(props.questions.filter((_, index) => index !== questionIndex))
    }
  }

  const setQuestionAnswer = (questionIndex, answerIndex, lang, value) => {
    props.setQuestions(props.questions.map((question, qIndex) => {
      if (qIndex === questionIndex) {
        const fieldName = lang === 'en' ? 'answersEn' : 'answersFi'

        question[fieldName] = question[fieldName].map((answer, aIndex) => {
          if (aIndex === answerIndex) {
            return value
          }
          return answer
        })
      }
      return question
    }))
  }

  const deleteAnswer = (questionIndex, answerIndex) => {
    const confirm = window.confirm(t('confirm_delete_answer'))
    if (confirm) {
      props.setQuestions(props.questions.map((question, qIndex) => {
        if (qIndex === questionIndex) {
          return {
            ...question,
            answersFi: question.answersFi.filter((_, aIndex) => aIndex !== answerIndex),
            answersEn: question.answersEn.filter((_, aIndex) => aIndex !== answerIndex)
          }
        }
        return question
      }))
    }
  }

  const addAnswer = (questionIndex) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        return {
          ...question,
          answersFi: [...question.answersFi, ''],
          answersEn: [...question.answersEn, '']
        }
      }
      return question
    }))
  }

  const moveQuestion = (index, direction) => {
    const newQuestions = [...props.questions];

    if (direction === 'up') {
      if (index === 0) return
      const temp = newQuestions[index - 1]
      newQuestions[index - 1] = newQuestions[index]
      newQuestions[index] = temp
    } else if (direction === 'down') {
      if (index === props.questions.length - 1) return
      const temp = newQuestions[index + 1]
      newQuestions[index + 1] = newQuestions[index]
      newQuestions[index] = temp
    }
    props.setQuestions(newQuestions)
  }

  const renderIntroContent = () => {
    if (langTab === 'en') {
      return (
        <>
          <Input
            label={t('title_en')}
            value={props.titleEn}
            onChange={props.setTitleEn}
            {...validationMessage(props.titleEn)}
          />
          <Input
            label={t('content_en')}
            value={props.contentEn}
            onChange={props.setContentEn}
            multiline
            {...validationMessage(props.contentEn)}
          />
        </>
      )
    }
    return (
      <>
        <Input
          label={t('title_fi')}
          value={props.titleFi}
          onChange={props.setTitleFi}
          {...validationMessage(props.titleFi)}
        />
        <Input
          label={t('content_fi')}
          value={props.contentFi}
          onChange={props.setContentFi}
          multiline
          {...validationMessage(props.contentFi)}
        />
      </>
    )
  }

  const renderQuestions= () => {
    return (
      <div>
        {props.questions.map((question, index) => {
          return (
            <PollQuestionForm
              question={question}
              index={index}
              totalQuestions={props.questions.length}
              moveQuestionUp={() => moveQuestion(index, 'up')}
              moveQuestionDown={() => moveQuestion(index, 'down')}
              deleteQuestion={() => deleteQuestion(index)}
              setTitle={(lang, title) => setQuestionTitle(index, lang, title)}
              setContent={(lang, content) => setQuestionContent(index, lang, content)}
              setType={(type) => setQuestionType(index, type)}
              toggleOptional={() => toggleQuestionOptional(index)}
              setAnswer={(answerIndex, lang, value) => setQuestionAnswer(index, answerIndex, lang, value)}
              addAnswer={() => addAnswer(index)}
              deleteAnswer={(answerIndex) => deleteAnswer(index, answerIndex)}
              submitted={props.submitted}
              locked={locked}
              toggleLock={toggleLock}
            />
          )
        })}
        <Button text={t('add_question')} onClick={addQuestion} />
      </div>
    )
  }

  const getPollStatus = (lang) => {
    if (lang === 'fi') {
      if (props.titleFi && props.contentFi) return 'green'
      if (props.titleFi || props.contentFi) return 'yellow'
      return 'red'
    } else {
      if (props.titleEn && props.contentEn) return 'green'
      if (props.titleEn || props.contentEn) return 'yellow'
      return 'red'
    }
  }

  const renderLangSelect = () => {
    return (
      <div className={classes.actionButtons}>
        {['fi', 'en'].map(lang => (
          <PollLangButton
            key={lang}
            lang={lang}
            active={langTab === lang}
            onClick={() => setLangTab(lang)}
            status={getPollStatus(lang)}
          />
        ))}
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <Title title={t('poll_details')} />
      <div className={classes.introContainer}>
        <div className={classes.introHeader}>
          <h3 className={classes.title}>
            {t('poll_introduction')}
          </h3>
          {renderLangSelect()}
        </div>
        {renderIntroContent()}

        <div>
          {t('repeating_poll')}
        </div>
        <Switch
          checked={!!props.multiAnswer}
          onChange={() => props.setMultiAnswer(!props.multiAnswer)}
          label={t('allow_multiple_answers_after_24h')}
        />

      </div>

      {renderQuestions()}
    </div>
  )
}

export default PollForm
