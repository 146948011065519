import React from 'react'
import { EditorState } from 'draft-js'
import { makeStyles } from '@material-ui/core'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Editor } from 'react-draft-wysiwyg'
import { Colors } from '../../Utils/theme'

export const CUSTOM_TOOLBAR_SETTINGS = {
  options: ['inline', 'blockType', 'list', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline']
  },
  list: {
    options: ['ordered', 'unordered']
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3'],
  }
}

const useStyles = makeStyles((theme) => ({
  editor: {
    padding: ' 0 1rem 1rem',
    border: `1px solid ${Colors.lightGrey}`,
    minHeight: '14rem'
  },
  inputLabel: {
    fontSize: '1rem',
    marginBottom: '.25rem'
  },
  editorContainer: {
    border: `1px solid ${Colors.lightBorder}`,
    background: Colors.white,
    borderRadius: 3,
    padding: 1
  }
}))

export default function EditorInput(props) {
  const editor = React.useRef(null)
  const classes = useStyles()

  if (props.newLabel) {
    return (
      <div className={'new-editor-input-container'}>
        <div className={classes.inputLabel}>{props.label}</div>
        <div className={classes.editorContainer}>
          <Editor
            ref={editor}
            placeholder={props.placeholder}
            editorState={props.value || EditorState.createEmpty()}
            onEditorStateChange={props.onChange}
            toolbar={CUSTOM_TOOLBAR_SETTINGS}
            editorClassName={classes.editor}
            stripPastedStyles
          />
        </div>
      </div>
    )
  }


  return (
    <Editor
      ref={editor}
      placeholder={props.placeholder}
      editorState={props.value || EditorState.createEmpty()}
      onEditorStateChange={props.onChange}
      toolbar={CUSTOM_TOOLBAR_SETTINGS}
      editorClassName={classes.editor}
      stripPastedStyles
    />
  )
}
