import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Input, Button } from '../../Components'
import { Colors } from '../../Utils/theme'
import get from 'lodash/get'
import { GOOGLE_MAPS_API_KEY } from '../../Constants'

const MAP_HEIGHT = 400
const MAP_WIDTH = 600

const useStyles = makeStyles((theme) => ({
  column: {
    flex: 1
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0
  },
  spacer: {
    width: 32
  },
  coordsRow: {
    maxWidth: '30rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  coordsButton: {
    marginBottom: '1.25rem',
    marginLeft: '1.5rem'
  },
  mapsPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: Colors.cardBackgroundColor,
    color: Colors.content,
    height: MAP_HEIGHT,
    width: MAP_WIDTH,
    marginBottom: '1.5rem'
  },
  mapContainer: {
    marginBottom: '1.5rem'
  }
}))

const CouponOrganizationForm = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSetLatitude = (value) => {
    props.setLatitude(value.replace(',', '.').replace(/[^0-9.]/g, ''))
  }
  const handleSetLongitude = (value) => {
    props.setLongitude(value.replace(',', '.').replace(/[^0-9.]/g, ''))
  }

  const getCoordinates = () => {
    const { address } = props
    const API_URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`
    try {
      window.fetch(API_URL)
        .then((raw) => raw.json())
        .then((response) => {
          const newLat = get(response, 'results[0].geometry.location.lat')
          const newLon = get(response, 'results[0].geometry.location.lng')
          if (newLat && newLon) {
            handleSetLatitude(newLat.toString())
            handleSetLongitude(newLon.toString())
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  const renderMap = () => {
    if (!props.latitude || !props.longitude) {
      return (
        <div className={classes.mapsPlaceholder}>
          <p>{t('enter_coordinates_to_display_map')}</p>
        </div>
      )
    }
    return (
      <iframe
        src={`https://maps.google.com/maps?q=${props.latitude},${props.longitude}&hl=fi&z=10&output=embed`}
        width={MAP_WIDTH}
        height={MAP_HEIGHT}
        frameBorder={0}
        style={{ border: 0 }}
        aria-hidden='false'
        tabIndex={0}
        title='map'
      />
    )
  }

  return (
    <>
      <Title title={t('organization_details')} description={t('organization_details_description')} />
      <div>
        <Input
          label={t('name')}
          value={props.name}
          onChange={props.setName}
        />
        <Input
          label={t('address')}
          value={props.address}
          onChange={props.setAddress}
        />
        <div className={classes.coordsRow}>
          <div className={classes.column}>
            <Input
              label={t('latitude')}
              value={props.latitude}
              onChange={handleSetLatitude}
            />
          </div>
          <div className={classes.spacer} />
          <div className={classes.column}>
            <Input
              label={t('longitude')}
              value={props.longitude}
              onChange={handleSetLongitude}
            />
          </div>
          <Button
            buttonStyle={classes.coordsButton}
            text={t('get_coordinates')}
            onClick={getCoordinates}
            small
            secondary
          />
        </div>
        <div className={classes.mapContainer}>
          {renderMap()}
        </div>
        <Title
          title={t('contact_information')}
          type='subtitle'
        />
        <Input
          label={t('email')}
          value={props.email}
          onChange={props.setEmail}
        />
        <Input
          label={t('phone')}
          value={props.phone}
          onChange={props.setPhone}
        />
        <Input
          label={t('url')}
          value={props.url}
          onChange={props.setUrl}
        />
      </div>
    </>
  )
}

export default CouponOrganizationForm
