import { capitalize, uniq } from 'lodash'
import moment from 'moment'

export const validatePoll = (poll, checkLocales = ['fi', 'en']) => {
  // Capitalize locales
  const locales = checkLocales.map(val => capitalize(val))

  // Don't validate drafts
  if (poll.draft) return null

  // No questions set -> activate / deactivate action
  if (!poll.questions) return null

  // Initialize locale field counters
  const fieldCounters = {}
  for (const locale of locales) {
    fieldCounters[locale] = 0
  }

  for (const locale of locales) {
    for (const field of ['title', 'content']) {
      if (poll[`${field}${locale}`]) {
        fieldCounters[locale] = fieldCounters[locale] + 1
      }
    }

    if (!poll.questions.length) {
      return 'validation_missing_question'
    }

    // Check that questions are either filled or empty
    for (const question of poll.questions) {
      for (const field of ['title', 'content']) {
        if (question[`${field}${locale}`]) {
          fieldCounters[locale] = fieldCounters[locale] + 1
        }
      }
      if (question.type !== 'text') {
        if (question[`answers${locale}`]) {
          // Check for empty answer value
          if (question[`answers${locale}`].filter(val => !val).length) {
            return 'validation_fill_all_fields'
          }

          fieldCounters[locale] = fieldCounters[locale] + (question[`answers${locale}`].filter(val => !!val)).length
        }
      }
    }
  }
  const allCountersZero = !!locales.filter(locale => {
    return !fieldCounters[locale]
  }).length
  if (allCountersZero) {
    return 'validation_fill_all_fields'
  }

  const diffCounts = uniq(locales.map(locale => fieldCounters[locale])).length > 1
  if (diffCounts) {
    return 'validation_missing_fields'
  }

  // Validate dates
  if (!poll.validFrom) return 'validation_missing_valid_from'
  if (!poll.validUntil) return 'validation_missing_valid_until'

  if (moment(poll.validUntil).isBefore(moment(poll.validFrom))) {
    return 'validation_valid_until_before_valid_from'
  }

  return null
}

export const validateCoupon = (coupon) => {
  // Validate required fields
  const fields = ['categoryId', 'organizationId', 'validFrom', 'validUntil']
  for (const field of fields) {
    if (!coupon[field]) return 'validation_fill_all_fields'
  }

  // Validate dates
  if (!coupon.validFrom) return 'validation_missing_valid_from'
  if (!coupon.validUntil) return 'validation_missing_valid_until'

  if (moment(coupon.validUntil).isBefore(moment(coupon.validFrom))) {
    return 'validation_valid_until_before_valid_from'
  }

  // Validate localized fields
  const localizedFields = ['title', 'content']
  const localeCounters = {
    Fi: 0,
    En: 0
  }
  for (const locale of ['Fi', 'En']) {
    for (const localizedField of localizedFields) {
      if (coupon[`${localizedField}${locale}`]) {
        localeCounters[locale] = localeCounters[locale] + 1
      }
    }
  }
  if (localeCounters.Fi === 0 && localeCounters.En === localizedFields.length) return null
  if (localeCounters.En === 0 && localeCounters.Fi === localizedFields.length) return null
  if (localeCounters.Fi === localizedFields.length && localeCounters.En === localizedFields.length) return null
  return 'validation_fill_all_fields'
}
