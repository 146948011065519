import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as get from 'lodash/get'
import PhotoIcon from '../../Assets/Icons/photo.svg'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import { formatDateTime } from '../../Utils/dateTime'
import { withRouter } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: 5
  },
  truncated: {
    maxWidth: '16rem',
    maxHeight: '4em',
    overflow: 'hidden'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 400,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem'
    }
  },
  firstCell: {
    fontWeight: 600,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    fontWeight: 700
  },
  categoryBadge: {
    background: 'rgba(0, 0, 0, .03)',
    textAlign: 'center',
    display: 'inline-block',
    borderRadius: 5,
    padding: '.5rem .75rem'
  },
  icon: {
    height: '2rem'
  }
}))

function FeedbackTableRow (props) {
  const { item } = props
  const classes = useStyles()

  const handleClick = () => {
    if (props.type === 'city_feedback') {
      props.history.push(`/feedbacks/city-feedback/${item.id}`)
    } else {
      props.history.push(`/feedbacks/fault-report/${item.id}`)
    }
  }

  const getTitle = () => {
    if (props.type === 'city_feedback') return item.title
    return `${item.mainCategory}: ${item.subCategory}`.trim().replace(/\:+$/, '')
  }

  return (
    <TableRow classes={{ root: classes.root }} hover>
      <TableCell onClick={handleClick} className={[classes.cell, classes.firstCell].join(' ')}>
        {item.id}
      </TableCell>
      <TableCell onClick={handleClick} className={[classes.cell].join(' ')}>
        <div className={classes.truncated}>
          <span>{getTitle()}</span>
        </div>
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>
        {(!item.mediaId && !item.photo) ? null : <img className={classes.icon} src={PhotoIcon} alt='media' />}
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>{get(item, 'email')}</TableCell>
      <TableCell onClick={handleClick} className={[classes.cell, classes.lastCell].join(' ')}>{formatDateTime(item.createdAt)}</TableCell>
    </TableRow>
  )
}

export default withRouter(FeedbackTableRow)
