import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import PollLangButton from './PollLangButton'
import { Button, Title, Input, Select, Switch } from '../'
import OrderUp from '../../Assets/Icons/order-up.svg'
import OrderDown from '../../Assets/Icons/order-down.svg'
import DeleteOutlined from '../../Assets/Icons/delete-outlined.svg'
import IconLock from '../../Assets/Icons/lock.svg'
import IconLockOpen from '../../Assets/Icons/lock-open.svg'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) =>({
  container: {
    backgroundColor: Colors.lightGrey,
    padding: '1rem',
    border: '1px solid rgba(0,0,0,.2)',
    position: 'relative',
    marginBottom: '2rem'
  },
  header: {
    flexDirection: 'row',
    paddingBottom: '1rem'
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 400,
    margin: 0,
    padding: '0 0 .75rem',
    flex: 1
  },
  actionButtons: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  answerOptionContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  actionButton: {
    margin: 0,
    padding: '.5rem 0',
    borderRadius: 5,
    background: Colors.white,
    '&:hover': {
      background: Colors.white
    },
    width: '2.25rem',
    height: '2.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '.75rem',
    cursor: 'pointer'
  },
  buttonIcon: {
    maxHeight: '1rem',
    maxWidth: '1rem'
  },
  deleteButton: {
    border: `1px solid ${Colors.red}`,
    background: 'transparent',
    marginLeft: '1rem',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    '&:hover': {
      background: 'transparent'
    }
  }
}))

function PollQuestionForm (props) {
  const classes = useStyles()

  const { t } = useTranslation()

  const getQuestionTypeOptions = () => [
    {
      label: t('answer_type_select'),
      value: 'select'
    },
    {
      label: t('answer_type_multiselect'),
      value: 'multiselect'
    },
    {
      label: t('answer_type_top3'),
      value: 'ranked_multiselect'
    },
    {
      label: t('answer_type_text'),
      value: 'text'
    }
  ]

  const [langTab, setLangTab] = useState('fi')
  const langSuffix = langTab === 'en' ? t('lang_suffix_en') : t('lang_suffix_fi')

  const charIndex = (number) => number < 26 ? String.fromCharCode(number + 65) : number

  const getQuestionStatus = (lang) => {
    const question = props.question

    if (lang === 'en') {
      if (!question.titleEn && !question.contentEn) return 'red'
      if (!question.titleEn || !question.contentEn) return 'yellow'

      if (question.type !== 'text') {
        if (question.answersEn.length !== question.answersEn.filter(a => !!a).length) {
          return 'yellow'
        }
      }
      return 'green'
    } else {
      if (!question.titleFi && !question.contentFi) return 'red'
      if (!question.titleFi || !question.contentFi) return 'yellow'

      if (question.type !== 'text') {
        if (question.answersFi.length !== question.answersFi.filter(a => !!a).length) {
          return 'yellow'
        }
      }
      return 'green'
    }
  }

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const renderQuestionAnswers = () => {
    const { question } = props
    const fieldName = langTab === 'en' ? 'answersEn' : 'answersFi'

    if (question.type === 'text') return null

    return question[fieldName].map((answer, answerIndex) => (
      <div key={answerIndex} className={classes.answerOptionContainer}>
        <Input
          value={answer}
          onChange={(val) => props.setAnswer(answerIndex, langTab, val)}
          label={`${t('answer_option_num')} ${charIndex(answerIndex)} ${langSuffix}`}
          {...validationMessage(answer)}
        />
        {question[fieldName].length > 2 ?
          (
            <Button
              onClick={() => props.deleteAnswer(answerIndex)}
              icon={DeleteOutlined}
              buttonStyle={classes.deleteButton}
            />
          ) : null
        }
      </div>
    ))
  }

  const renderQuestion = () => {
    const { question } = props

    const title = question[langTab === 'en' ? 'titleEn' : 'titleFi']
    const content = question[langTab === 'en' ? 'contentEn' : 'contentFi']

    if (props.locked) {
      return (
        <Input
          label={`${t('question_title')}`}
          value={title} onChange={(val) => props.setTitle(langTab, val)}
          {...validationMessage(title)}
          disabled
        />
      )
    }

    return (
      <>
        <Input
          label={`${t('question_title')} ${langSuffix}`}
          value={title} onChange={(val) => props.setTitle(langTab, val)}
          {...validationMessage(title)}
        />
        <Input
          label={`${t('question_content')} ${langSuffix}`}
          value={content}
          onChange={(val) => props.setContent(langTab, val)}
          multiline
          {...validationMessage(content)}
        />
        <Select
          label={`${t('question_type')}`}
          value={question.type}
          onChange={(val) => props.setType(val)}
          options={getQuestionTypeOptions()}
          {...validationMessage(question.type)}
        />
        {question.type !== 'text' && (
          <>
            <div>
              {t('mandatory_question')}
            </div>
            <Switch
              checked={!!question.isOptional}
              onChange={props.toggleOptional}
              label={t('is_poll_optional')}
            />
          </>
        )}
        {question.type === 'text' ? null : (
          <>
            <Title title={t('answer_options')} type='subtitle' />
            {renderQuestionAnswers()}
            <Button text={t('add_answer')} onClick={props.addAnswer} small />
          </>
        )}
      </>
    )
  }

  const renderArrangeButtons = () => {
    const { index, totalQuestions } = props
    return (
      <>
        {(index > 0) ? (
          <div
            onClick={props.moveQuestionUp}
            className={classes.actionButton}
          >
            <img src={OrderUp} alt='up' className={classes.buttonIcon} />
          </div>
        ) : null}
        {(index < (totalQuestions - 1)) ? (
          <div
            onClick={props.moveQuestionDown}
            className={classes.actionButton}
          >
            <img src={OrderDown} alt='down' className={classes.buttonIcon} />
          </div>
        ) : null}
      </>
    )
  }

  const renderDeleteButton = () => {
    return (
      <div
        onClick={props.deleteQuestion}
        className={classes.actionButton}
      >
        <img src={DeleteOutlined} alt='delete' className={classes.buttonIcon} />
      </div>
    )
  }

  const renderLockButton = () => {
    const icon = props.locked ? IconLock : IconLockOpen
    return (
      <div
        onClick={props.toggleLock}
        className={classes.actionButton}
      >
        <img src={icon} alt='lock' className={classes.buttonIcon} />
      </div>
    )
  }

  const renderLangSelect = () => {
    return (
      <div className={classes.actionButtons}>
        {renderLockButton()}
        {renderArrangeButtons()}
        {['fi', 'en'].map(lang => (
          <PollLangButton
            key={lang}
            lang={lang}
            active={langTab === lang}
            onClick={() => setLangTab(lang)}
            status={getQuestionStatus(lang)}
          />
        ))}
        {renderDeleteButton()}
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h3 className={classes.title}>{`${t('question')} ${props.index + 1}.`}</h3>
        {renderLangSelect()}
      </div>
      {renderQuestion()}

    </div>
  )

}

export default PollQuestionForm
