import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  button: {
    cursor: 'pointer',
    color: Colors.content,
    fontSize: '1.125rem',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: 0,
    padding: '.5rem .75rem'
  },
  activeButton: {
    cursor: 'pointer',
    color: Colors.black,
    fontSize: '1.125rem',
    borderRadius: 5,
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: 0,
    padding: '.5rem .75rem'
  },
  buttonSpacer: {
    width: '.5rem'
  },
  redLight: {
    background: Colors.red,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  },
  greenLight: {
    background: Colors.green,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  },
  yellowLight: {
    background: Colors.yellow,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  }
}))

export default function ItemLangButtons(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderStatus = (lang) => {
    const status = lang === 'fi' ? props.statusFi : props.statusEn
    if (status === 'red') {
      return <div className={classes.redLight} />
    } else if (status === 'green') {
      return <div className={classes.greenLight} />
    } else if (status === 'yellow') {
      return <div className={classes.yellowLight} />
    }
    return null
  }

  const renderTabs = () => {
    const fiActive = props.currentLanguage === 'fi'
    const enActive = props.currentLanguage === 'en'

    return (
      <div className={classes.container}>
        <button
          className={fiActive ? classes.activeButton : classes.button}
          onClick={() => props.setLanguage('fi')}
        >
          {'FI'}
          {renderStatus('fi')}
        </button>
        <div className={classes.buttonSpacer} />
        <button
          className={enActive ? classes.activeButton : classes.button}
          onClick={() => props.setLanguage('en')}
        >
          {'EN'}
          {renderStatus('en')}
        </button>
      </div>
    )
  }
  return renderTabs()
}
